import React from "react";
import { ScrollView, View, Text, StyleSheet, Image } from "react-native";

import {
  GTAmericaCompressed,
  greenBrand,
  blackBrand,
  TrailersDemiBold,
  PanamaBold,
  GTAmerica,
} from "../libs/constants";
import { CuratorHeader } from "../components/curator/CuratorHeader";
import { PhoneNumberSignupForm } from "../components/forms/PhoneNumberSignupForm";
import { EventSlice } from "../components/events/EventSlice";
import { RouteComponentProps } from "../navigation";
import { useEffect } from "react";
import { mdBreakpoint } from "../constants";
import { useDimensions } from "../context/DimensionsContext";
import { HoverableLink } from "../components/common/HoverableLink";
import { useThemeState } from "../context/ThemeContext";
import { H1, P } from "@expo/html-elements";
import { TextureUvs } from "pixi.js";

interface Props { }

export const EventsLandingScreen: React.FC<
  RouteComponentProps<{
    isSmsSubscriber: string;
  }>
> = (props: RouteComponentProps<{ isSmsSubscriber: string }>) => {
  document.title = "Events | Happy Medium";
  const scrollRef = React.useRef(null);
  const { width } = useDimensions();
  const { theme } = useThemeState();
  const {
    match: {
      params: { isSmsSubscriber },
    },
  } = props;

  const subscribed = isSmsSubscriber === "subscribed";
  console.log(
    "SMS??",
    isSmsSubscriber,
    isSmsSubscriber === "subscribed",
    subscribed
  );
  const measuringTape = require("@happymedium/components/assets/images/blue-white-measure-tape-long-horizontal.png");
  const styles = StyleSheet.create({
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontWeight: "300",
      fontSize: 24,
      color: blackBrand,
    },
    body: {
      color: theme.foregroundColor,
      fontFamily: GTAmerica,
      fontSize: width < mdBreakpoint ? 14 : 16,
    },
  });

  const mobileEventHeight = 740;
  const desktopEventHeight = 480;

  useEffect(() => {
    if (subscribed) {
      //@ts-ignore
      scrollRef.current!.scrollTo({
        y:
          width < mdBreakpoint
            ? mobileEventHeight * 9.45
            : desktopEventHeight * 9.74,
      });
    }
  }, []);

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: theme.backgroundColor,
      }}
      ref={scrollRef}
    >
      <CuratorHeader
      // backgroundColor={greenBrand}
      // hideThemePicker={true}
      // logoColor={'black'}
      // linkColor={blackBrand}
      // isRow={true}
      />
      <View
        style={{
          maxWidth: width < mdBreakpoint ? "90%" : 540,
          marginTop: width < mdBreakpoint ? 20 : 60,
          alignSelf: "center",
          alignItems: "flex-start",
          // paddingLeft: 20,
          // paddingRight: 20,
          backgroundColor: theme.backgroundColor,
        }}
      >
        <H1
          style={{
            color: theme.foregroundColor,
            fontFamily: PanamaBold,
            fontWeight: "normal",
            fontSize: width < mdBreakpoint ? 24 : 38,
            letterSpacing: 1.2,
            width: "100%",
            textTransform: "uppercase",
            alignSelf: "flex-start",
          }}
        >
          Classes & Figure Drawing
        </H1>
        {/* <View style={{ height: 20 }} /> */}
        <Text style={[styles.body]}>
          Happy Medium's events are designed for the adult beginner who wants to
          have fun creating instead of consuming for a few hours with zero
          expectation of being "good enough." To learn more, check out our{" "}
          <HoverableLink
            linkUrl="/events/faq"
            linkType="internal"
            textStyle={{ alignSelf: "center" }}
          >
            <Text style={[styles.body]}>event FAQs</Text>
          </HoverableLink>
          .
          <P style={[styles.body, { fontSize: 14, fontWeight: "bold" }]}>
            **We are unable to accomodate refunds on ticket purchases. You must
            notify us at reservations@happy-medium.co at least 24 hours before
            your time slot in order to be eligible to reschedule. Otherwise, the
            full price will be forfeit.**
          </P>
          {`\n\n`}
          Check out our guide to an evening of figure drawing{" "}
          <HoverableLink
            linkUrl="/events/figure-drawing"
            linkType="internal"
            textStyle={{ alignSelf: "center" }}
          >
            <Text style={[styles.body]}>here</Text>
          </HoverableLink>
          !
        </Text>
      </View>
      {!subscribed && (
        <>
          <View style={{ height: 20 }} />
          <View
            style={{
              backgroundColor: theme.foregroundColor,
              paddingTop: 20,
              paddingBottom: 30,
              width: "100vw",
              maxWidth: 584,
              alignSelf: "center",
            }}
          >
            <Text
              style={[
                styles.infoText,
                {
                  fontSize: 22,
                  fontFamily: TrailersDemiBold,
                  textTransform: "uppercase",
                  letterSpacing: 1.4,
                  textAlign: "center",
                  color: theme.backgroundColor,
                },
              ]}
            >
              Sign up to be the first to know about new events
            </Text>
            <View style={{ alignItems: "center", marginTop: 5 }}>
              <PhoneNumberSignupForm />
            </View>
          </View>
        </>
      )}
      <View style={{ width: 30, height: 40 }} />
      <View />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Monday, January 20th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-01-20-figure-drawing.png"
        eventSubtitle="with special guest, Lauren Gerrie."
        eventUrl="/event/252"
        soldOut={true}
        ticketUrl="https://buy.stripe.com/4gwaIk9T66aaa6R3jZ"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Tuesday, January 21st, 2024"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-01-21-figure-drawing.png"
        eventSubtitle="with special guest, Laura."
        eventUrl="/event/252"
        soldOut={true}
        ticketUrl="https://buy.stripe.com/28ocQsaXabuu1Al5rP"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, January 22nd, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-01-22-figure-drawing.png"
        eventSubtitle="with special guest, Shea."
        eventUrl="/event/252"
        soldOut={true}
        ticketUrl="https://buy.stripe.com/5kA03G9T6dCCbaV8Ea"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="The Art of Watercolor"
        eventDate="Saturday, January 25th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-01-25-watercolor-still-life.png"
        eventSubtitle="with Claire Wilson-Oghedo"
        eventUrl="/event/252"
        soldOut={true}
        ticketUrl="https://buy.stripe.com/28o6s46GUfKKa6R9I6"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Build-a-Lamp"
        eventDate="Saturday, January 25th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-01-25-Lamp.png"
        eventSubtitle=""
        eventUrl="/event/252"
        soldOut={true}
        ticketUrl="https://buy.stripe.com/5kAcQs1mA0PQ92N7A3"
        location="224 Franklin St, Brooklyn, NY 11222"
      />
      <EventSlice
        eventTitle="Paint Like M*tisse"
        eventDate="Sunday, January 26th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-01-26-Matisse.png"
        eventSubtitle="with Team HM"
        eventUrl="/event/252"
        soldOut={true}
        ticketUrl="https://buy.stripe.com/eVa03Gfdq422gvf1bP"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Monday, January 27th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-01-27-figure-drawing.png"
        eventSubtitle="with special guest, Lauren Gerrie."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/5kA2bOc1egOOceZ07x"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Tuesday, January 28th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-01-28-figure-drawing.png"
        eventSubtitle="with special guest, Ella."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/9AQ8Acd5ifKK6UF2fH"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, January 29th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-01-29-figure-drawing.png"
        eventSubtitle="with special guest, Bola Bee."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/7sI8Ace9meGGceZ2fI"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Picture Day: The Art of Self Portraiture"
        eventDate="Saturday, February 1st, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-01-Picture-Day.png"
        eventSubtitle="with Matthieu Dougherty"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/cN22bOaXa9mmceZ5tu"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Build-a-Lamp"
        eventDate="Sunday, February 2nd, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-02-Lamp.png"
        eventSubtitle=""
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/3cs5o09T68iifrb2iK"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Monday, February 3rd, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-03-figure-drawing.png"
        eventSubtitle="with special guest, Ume."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/5kA3fSd5ifKK4MxbRG"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, February 5th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-05-long-pose-figure-drawing.png"
        eventSubtitle="with special guest, Ella."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/dR6dUw6GU9mmgvf7Bt"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="The Art of Collage"
        eventDate="Saturday, February 8th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-08-collage.png"
        eventSubtitle="with James Gallagher"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/00gg2E3uI5666UF4pk"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="The Art of Watercolor: Still Life"
        eventDate="Saturday, February 8th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-08-watercolor-still-life.png"
        eventSubtitle="with Claire Wilson-Oghedo"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/fZe17K3uIbuu3Itf4b"
        location="224 Franklin St, Brooklyn, NY 11222"
      />
      <EventSlice
        eventTitle="The Art of Oil Painting"
        eventDate="Sunday, February 9th, 2025"
        eventTime="9:00am - 12:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-09-oil.png"
        eventSubtitle="with Paul Ferney"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/7sI8Acc1e2XYdj34pB"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Monday, February 10th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-10-figure-drawing.png"
        eventSubtitle="with special guest, Bola Bee."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/bIY8Ac9T6aqq7YJ1d3"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Tuesday, February 11th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-11-figure-drawing.png"
        eventSubtitle="with special guest, Laura."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/4gwcQs9T6dCCen72ha"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, February 12th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-12-figure-drawing.png"
        eventSubtitle="with special guest, Lauren Gerrie."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/28o4jW0iw0PQfrb2hk"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing 101"
        eventDate="Saturday, February 15th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-15-figure-drawing-101.png"
        eventSubtitle="with Matthieu Dougherty"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/28ocQsfdq1TUfrbaNP"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="The Art of Crochet"
        eventDate="Sunday, February 16th, 2025"
        eventTime="9:00am - 12:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-16-crochet.png"
        eventSubtitle="with Team HM"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/14k7w85CQ9mm2EpcVU"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Build-a-Lamp"
        eventDate="Sunday, February 16th, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-02-Lamp.png"
        eventSubtitle=""
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/bIY8Ac5CQ5665QB8FO"
        location="224 Franklin St, Brooklyn, NY 11222"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Monday, February 17th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-17-figure-drawing.png"
        eventSubtitle="with special guest, Bola Bee."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/dR68Ac2qE0PQen79JT"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Tuesday, February 18th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-18-figure-drawing.png"
        eventSubtitle="with special guest, Laura."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/6oE5o0fdq6aa5QB5tt"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, February 19th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-19-figure-drawing.png"
        eventSubtitle="with special guest."
        eventUrl="/event/252"
        soldOut={true}
        ticketUrl=""
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="The Art of Watercolor: Still Life"
        eventDate="Saturday, February 22nd, 2025"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-22-watercolor-still-life.png"
        eventSubtitle="with Claire Wilson-Oghedo"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/fZe9Eg9T6aqqceZaNY"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Paint Like M*tisse"
        eventDate="Sunday, February 23rd, 2025"
        eventTime="9:00am - 12:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-23-Matisse.png"
        eventSubtitle="with Team HM"
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/eVa6s4d5i2XY5QB4pC"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Monday, February 24th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-24-figure-drawing.png"
        eventSubtitle="with special guest, Bola Bee."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/8wM03Gghu9mm92N6xo"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Tuesday, February 25th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-25-figure-drawing.png"
        eventSubtitle="with special guest, Ella."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/aEUeYAaXa8ii5QBdZT"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, February 26th, 2025"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2025-02-26-figure-drawing.png"
        eventSubtitle="with special guest, Lauren Gerrie."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/3cs6s48P22XYfrbf47"
        location="49 Market St, NY, NY 10002"
      />

      <View style={{ height: 40 }} />
      <Image
        source={measuringTape}
        style={{
          height: 45,
          width: 450,
          maxWidth: "100vw",
          resizeMode: "contain",
          marginTop: 40,
          paddingBottom: 40,
          alignSelf: "center",
          transform: [{ rotate: "-10deg" }],
          left: 200,
        }}
      />
    </ScrollView>
  );
};

// <EventSlice
//         key={'AOPD_JUL312022'}
//         eventTitle="The Art of Plywood Design"
//         eventDate="Sunday, July 31st, 2022"
//         eventTime="10:00am - 2:00pm"
//         eventImgUrl="https://49market.s3.amazonaws.com/2022-07-31_art-of-plywood-design.png"
//         eventSubtitle="with Rett Carraway."
//         eventUrl="/event/208"
//         soldOut={false}
//         ticketUrl="https://buy.stripe.com/dR63fSd5i5662EodUt"
//       />
