import { H2 } from "@expo/html-elements";
import React from "react";
import {
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { HoverableLink } from "../components/common/HoverableLink";
import { PrimaryButton } from "../components/common/PrimaryButton";
import { TouchableOpacity } from "../components/common/TouchableOpacity";
import { CuratorHeader } from "../components/curator/CuratorHeader";
import { SalonSignup } from "../components/salon/SalonSignup";
import { smBreakpoint } from "../constants";
import { useDimensions } from "../context/DimensionsContext";
import { useThemeState } from "../context/ThemeContext";
import {
  blackBrand,
  GTAmerica,
  GTAmericaCompressed,
  mdbreakpoint,
  PanamaBold,
  redBrand,
  TrailersDemiBold,
  wineBrand,
} from "../libs/constants";

interface Props { }

export const KatieRomeroEventScreen: React.FC<Props> = () => {
  const { theme } = useThemeState();
  const { width, height } = useDimensions();
  const styles = StyleSheet.create({
    text: {
      color: theme.foregroundColor,
    },
    secondaryHeader: {
      fontFamily: GTAmericaCompressed,
      textTransform: "uppercase",
      color: theme.foregroundColor,
      fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: "300",
      fontSize: width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
    },
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.02,
    },
  });
  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <CuratorHeader />
      <Image
        resizeMode="cover"
        source={{ uri: "https://49market.s3.amazonaws.com/art_cafe_1.png" }}
        style={{
          width: width,
          height: width / 1.5,
          maxWidth: 1560,
          maxHeight: 1170,
          alignSelf: "center",
        }}
      />
      <View style={{ height: 40 }} />
      <View
        style={{
          justifyContent: "center",
          width: 700,
          maxWidth: "90vw",
          alignSelf: "center",
        }}
      >
        <Text
          style={{
            fontFamily: PanamaBold,
            color: theme.foregroundColor,
            textTransform: "uppercase",
            fontSize:
              width < smBreakpoint ? 22 : width < mdbreakpoint ? 32 : 48,
            letterSpacing: 1.02,
            lineHeight:
              width < smBreakpoint ? 24 : width < mdbreakpoint ? 34 : 50,
          }}
        >
          TikTok Meetup
        </Text>
        <Text
          style={{
            fontFamily: GTAmerica,
            fontWeight: "300",
            color: theme.foregroundColor,
            fontSize:
              width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
            letterSpacing: 1.07,
          }}
        >
          hosted by Katie Romero
        </Text>
        <View style={{ height: 20 }} />
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ flexDirection: "column", justifyContent: "center" }}>
            <Text style={styles.secondaryHeader}>Thursday, December 12th</Text>
            <Text
              style={{
                fontFamily: GTAmerica,
                fontWeight: "300",
                color: theme.foregroundColor,
                fontSize:
                  width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
                letterSpacing: 1.07,
              }}
            >
              6:45pm - 8:45PM
            </Text>
            <Text
              style={{
                fontFamily: GTAmerica,
                fontWeight: "300",
                color: theme.foregroundColor,
                fontSize:
                  width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
                letterSpacing: 1.07,
              }}
            >
              49 Market St{"\n"}
              New York, NY 10002
            </Text>
          </View>
          <View>
            <HoverableLink
              disabled={true}
              linkType="web"
              linkUrl={"https://buy.stripe.com/fZe5o0fdqcyy7YJg6g"}
            >
              <Hoverable>
                {(isHovered) => (
                  <View
                    style={{
                      backgroundColor: theme.foregroundColor,
                      paddingVertical: 8,
                      borderRadius: 30,
                      opacity: 1,
                      alignItems: "center",
                      marginTop: 5,
                      // width: '100%',
                      // width: 250,
                      paddingHorizontal: 20,
                      // transform: [{ scale: isHovered ? 1.05 : 1 }],
                      shadowColor: blackBrand,
                      shadowOffset: { width: 5, height: 5 },
                      shadowOpacity: 0.4,
                      shadowRadius: 10,
                    }}
                  >
                    {/* <Text
                      style={[
                        styles.infoText,
                        {
                          textTransform: "uppercase",
                          fontWeight: "400",
                          color: theme.backgroundColor,
                        },
                      ]}
                    >
                      Buy Tickets
                    </Text> */}
                    <Text
                      style={[
                        styles.infoText,
                        {
                          textTransform: "uppercase",
                          fontWeight: "400",
                          color: theme.backgroundColor,
                        },
                      ]}
                    >
                      Sold Out
                    </Text>
                  </View>
                )}
              </Hoverable>
            </HoverableLink>
          </View>
        </View>
        <View style={{ height: 20 }} />
        <Text style={[styles.text, styles.body]}>
          Join Katie Romero in Happy Medium's Manhattan art cafe for an
          evening of friendship and painting. Each ticket includes a fresh
          watercolor palette, watercolor paper, use of our house brushes, and
          all the inspiration you could want.
          {"\n\n"}
        </Text>
        <H2
          style={{
            fontFamily: PanamaBold,
            textTransform: "uppercase",
            color: theme.foregroundColor,
            fontSize:
              width < smBreakpoint ? 22 : width < mdbreakpoint ? 28 : 32,
          }}
        >
          The Details
        </H2>
        {/* <Text style={[styles.text, styles.body]}>
          Set in Happy Medium's bright, open, artist's loft - the kind you don't
          find in NYC anymore - this private event is the first of it's kind.
          {"\n\n"}
        </Text> */}
        <Text style={[styles.text, styles.body]}>
          Meet online friends IRL and bond over the messiness and
          unpredictability of watercolor with fresh, house made paints, thick
          watercolor paper, and all the supplies you could ever need.
          {"\n\n"}
        </Text>
        <Text style={[styles.text, styles.body]}>
          Nervous about making art? You're in the right spot. Happy Medium is an
          arts club for casual artists and designed specifically with beginners
          in mind. We'll send you all the tips you need beforehand so you can
          walk in feeling like a pro.
          {"\n\n"}
        </Text>
        <H2
          style={{
            fontFamily: PanamaBold,
            textTransform: "uppercase",
            color: theme.foregroundColor,
            fontSize:
              width < smBreakpoint ? 22 : width < mdbreakpoint ? 28 : 32,
          }}
        >
          FAQ
        </H2>
        <Text style={styles.secondaryHeader}>IS THIS TICKET REFUNDABLE?</Text>
        <Text style={[styles.text, styles.body]}>
          No, but we're happy to offer you a credit to be applied to any future
          events, products, or art cafe as long as you let us know you won't be
          able to make it at least 24 hours before the event start time. Contact us at reservations@happy-medium.co{"\n\n"}
        </Text>
        <Text style={styles.secondaryHeader}>IS THIS TICKET Transferable?</Text>
        <Text style={[styles.text, styles.body]}>
          Yes! If you can't make it but want to give your spot to a friend just
          send us an email at reservations@happy-medium.co and we'll be happy to
          help.{"\n\n"}
        </Text>
        <Text style={styles.secondaryHeader}>
          What's the address?
        </Text>
        <Text style={[styles.text, styles.body]}>
          49 Market St, New York, NY 10002{"\n\n"}
        </Text>
        {/* <Text style={styles.secondaryHeader}>
          What if it rains?
        </Text>
        <Text style={[styles.text, styles.body]}>
          This event is rain or shine. Weather permitting, it will be held outside in our garden but, should it rain, we will be meeting inside. {"\n\n"}
        </Text> */}
        <Text style={styles.secondaryHeader}>
          Can I buy more than one ticket?
        </Text>
        <Text style={[styles.text, styles.body]}>
          Yes! On mobile browsers, click "Details" and you'll find the quantity
          selector.{"\n\n"}
        </Text>
        <Text style={styles.secondaryHeader}>
          Will there be tickets available at the door?
        </Text>
        <Text style={[styles.text, styles.body]}>
          No. Tickets must be reserved in advance.{"\n\n"}
        </Text>
        <Text style={styles.secondaryHeader}>
          Do I need to know how to watercolor?
        </Text>
        <Text style={[styles.text, styles.body]}>
          Absolutely not - Happy Medium is designed for beginners and,
          specifically, adult beginners. We will send all of our best tips ahead
          of time so you feel like a pro!{"\n\n"}
        </Text>
        <Text style={styles.secondaryHeader}>Is this a class?</Text>
        <Text style={[styles.text, styles.body]}>
          No - this is a super casual, self-guided watercolor evening. Think
          of it like brunch vibes, but instead of consuming, you're creating.
          Our studio has hundreds of art books, art objects, flowers, and more
          that will inspire your hand and delight your eye.{"\n\n"}
        </Text>
        <Text style={styles.secondaryHeader}>Can I COME BY MYSELF?</Text>
        <Text style={[styles.text, styles.body]}>
          Yes! Singles are great, but we love friends too!{"\n\n"}
        </Text>
        <Text style={styles.secondaryHeader}>MORE QUESTIONS?</Text>
        <Text style={[styles.text, styles.body]}>
          Email us at reservations@happy-medium.co and we'd be happy to help!
          {"\n\n"}
        </Text>
        <View style={{ height: 60 }} />
      </View>
      <View style={{ height: 60 }} />
      <SalonSignup />
    </ScrollView>
  );
};
