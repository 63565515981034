import { A, H2 } from "@expo/html-elements";
import { TextProps } from "@expo/html-elements/build/primitives/Text";
import React from "react";
import {
    Image,
    Linking,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { HoverableLink } from "../components/common/HoverableLink";
import { PrimaryButton } from "../components/common/PrimaryButton";
import { TouchableOpacity } from "../components/common/TouchableOpacity";
import { CuratorHeader } from "../components/curator/CuratorHeader";
import { SalonSignup } from "../components/salon/SalonSignup";
import { smBreakpoint } from "../constants";
import { useDimensions } from "../context/DimensionsContext";
import { useThemeState } from "../context/ThemeContext";
import {
    blackBrand,
    GTAmerica,
    GTAmericaCompressed,
    mdbreakpoint,
    PanamaBold,
    redBrand,
    TrailersDemiBold,
    wineBrand,
} from "../libs/constants";

interface Props { }

export const TermsOfServiceScreen: React.FC<Props> = () => {
    document.title = "Terms of Service | Happy Medium";
    const { theme } = useThemeState();
    const { width, height } = useDimensions();
    const styles = StyleSheet.create({
        text: {
            color: theme.foregroundColor,
        },
        secondaryHeader: {
            fontFamily: GTAmericaCompressed,
            textTransform: "uppercase",
            color: theme.foregroundColor,
            fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
        },
        body: {
            fontFamily: GTAmerica,
            fontWeight: "300",
            fontSize: width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
        },
        infoText: {
            fontFamily: GTAmericaCompressed,
            fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
            letterSpacing: 1.02,
        },
    });
    return (
        <ScrollView
            style={{
                flex: 1,
                backgroundColor: theme.backgroundColor,
            }}
        >
            <CuratorHeader />
            {/* <Image
        resizeMode="contain"
        source={{ uri: 'https://49market.s3.amazonaws.com/art_cafe_1.png' }}
        style={{
          width: width,
          height: width / 1.333,
          maxWidth: 1560,
          maxHeight: 1170,
          alignSelf: 'center',
        }}
      /> */}
            <View style={{ height: 40 }} />
            <View
                style={{
                    justifyContent: "center",
                    width: 700,
                    maxWidth: "90vw",
                    alignSelf: "center",
                }}
            >
                <Text
                    style={{
                        fontFamily: PanamaBold,
                        color: theme.foregroundColor,
                        textTransform: "uppercase",
                        fontSize:
                            width < smBreakpoint ? 22 : width < mdbreakpoint ? 32 : 48,
                        letterSpacing: 1.02,
                        lineHeight:
                            width < smBreakpoint ? 24 : width < mdbreakpoint ? 34 : 50,
                    }}
                >
                    Terms of Service
                </Text>
                <Text
                    style={{
                        fontFamily: GTAmerica,
                        fontWeight: "300",
                        color: theme.foregroundColor,
                        fontSize:
                            width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
                        letterSpacing: 1.07,
                    }}
                >
                    please familiarize yourself with our terms and policies before purchasing
                </Text>
                <View style={{ height: 20 }} />
                {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
            <Text style={styles.secondaryHeader}>Saturday, April 2nd</Text>
            <Text
              style={{
                fontFamily: GTAmerica,
                fontWeight: '300',
                color: theme.foregroundColor,
                fontSize:
                  width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
                letterSpacing: 1.07,
              }}
            >
              12:00 - 2:00PM
            </Text>
          </View>
          <View>
            <HoverableLink disabled={true} linkType="web" linkUrl={''}>
              <Hoverable>
                {(isHovered) => (
                  <View
                    style={{
                      backgroundColor: theme.foregroundColor,
                      paddingVertical: 8,
                      borderRadius: 30,
                      alignItems: 'center',
                      marginTop: 5,
                      // width: '100%',
                      // width: 250,
                      paddingHorizontal: 20,
                      // transform: [{ scale: isHovered ? 1.05 : 1 }],
                      shadowColor: blackBrand,
                      shadowOffset: { width: 5, height: 5 },
                      shadowOpacity: 0.4,
                      shadowRadius: 10,
                    }}
                  >
                    <Text
                      style={[
                        styles.infoText,
                        {
                          textTransform: 'uppercase',
                          fontWeight: '400',
                          color: theme.backgroundColor,
                        },
                      ]}
                    >
                      SOLD OUT
                    </Text>
                  </View>
                )}
              </Hoverable>
            </HoverableLink>
          </View>
        </View> */}
                <View style={{ height: 20 }} />
                <Text style={styles.secondaryHeader}>Pottery Cancellation Policy</Text>
                <Text style={[styles.text, styles.body]}>
                    Please note, our tickets are non-refundable. You must notify us at pottery@happy-medium.co at least 48 hours before your time slot in order to be eligible to reschedule. Otherwise, the full price will be forfeit.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>
                    Figure Drawing Cancellation Policy
                </Text>
                <Text style={[styles.text, styles.body]}>
                    Please note, our tickets are non-refundable. You must notify us at reservations@happy-medium.co at least 24 hours before your time slot in order to be eligible to reschedule. Otherwise, the full price will be forfeit.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>Class Cancellation Policy</Text>
                <Text style={[styles.text, styles.body]}>
                    Please note, our tickets are non-refundable. You must notify us at reservations@happy-medium.co at least 24 hours before your time slot in order to be eligible to reschedule. Otherwise, the full price will be forfeit.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>
                    Build-a-Chair Cancellation Policy
                </Text>
                <Text style={[styles.text, styles.body]}>
                    Please note, our tickets are non-refundable. You must notify us at reservations@happy-medium.co at least 72 hours before your time slot in order to be eligible to reschedule. Otherwise, we will build your item for you and you can pick it up 1 week after the event.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>
                    Large Canvas Painting Experience Cancellation Policy
                </Text>
                <Text style={[styles.text, styles.body]}>
                    Please note, our tickets are non-refundable. You must notify us at reservations@happy-medium.co at least 72 hours before your time slot in order to be eligible to reschedule. Otherwise, we will build your item for you and you can pick it up 1 week after the event.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>
                    Art Cafe Reservation Deposit Cancellation Policy
                </Text>
                <Text style={[styles.text, styles.body]}>
                    Please note, Art Cafe deposits are non-refundable. You must notify us at reservations@happy-medium.co at least 24 hours before your time slot if someone cannot make it in order to be eligible for a credit that can be used towards any of our events or products, otherwise the deposit will be forfeit.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>
                    What happens if it rains on the day of my class or figure drawing?
                </Text>
                <Text style={[styles.text, styles.body]}>
                    We're excited to host events in our garden in Greenpoint. All garden events will take place rain or shine and there will be no exceptions made to our usual reschedule policy for weather. In addition to umbrellas provided by Happy Medium, we encourage guests to dress appropriately for the weather. Should Happy Medium choose to cancel the event, we will be in touch to issue a credit or refund.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>Gift Card Return Policy</Text>
                <Text style={[styles.text, styles.body]}>
                    Gift Cards are non-returnable and non-refundable.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>
                    Merch Return Policy
                </Text>
                <Text style={[styles.text, styles.body]}>
                    Merch including, but not limited to, hats, posters, and shirts are non-returnable and nonrefundable.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>Questions?</Text>
                <Text style={[styles.text, styles.body]}>
                    Please email reservations@happy-medium.co for any questions related to tickets.{"\n\n"}
                </Text>
                <View style={{ height: 60 }} />
            </View>
            <View style={{ height: 60 }} />
            <SalonSignup />
        </ScrollView>
    );
};
