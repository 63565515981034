import React, { useState } from "react";
import { ScrollView, StyleSheet, View, Text } from "react-native";
import { B, H1, H2, H3, LI, P } from "@expo/html-elements";
import { CuratorHeader } from "../components/curator/CuratorHeader";
import { smBreakpoint, lgBreakpoint } from "../constants";
import { useThemeState } from "../context/ThemeContext";
import {
  GTAmericaCompressed,
  mdbreakpoint,
  GTAmerica,
  PanamaBold,
} from "../libs/constants";
import { useDimensions } from "../context/DimensionsContext";
import { TouchableOpacity } from "../components/common/TouchableOpacity";
import { ImageCarousel } from "../components/common/ImageCarousel";
import { TextureUvs } from "pixi.js";

interface Props { }

export const PotteryScreen: React.FC<Props> = () => {
  document.title = "The Pottery | Happy Medium";

  const { theme } = useThemeState();
  const { width, height } = useDimensions();
  const styles = StyleSheet.create({
    text: {
      color: theme.foregroundColor,
    },
    secondaryHeader: {
      fontFamily: GTAmericaCompressed,
      textTransform: "uppercase",
      color: theme.foregroundColor,
      fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: "300",
      fontSize: width < smBreakpoint ? 16 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.07,
    },
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.02,
    },
    header: {
      fontFamily: PanamaBold,
      color: theme.foregroundColor,
      textTransform: "uppercase",
      alignSelf: "center",
      fontSize: width < smBreakpoint ? 34 : width < mdbreakpoint ? 42 : 48,
      letterSpacing: 1.02,
      lineHeight: width < smBreakpoint ? 38 : width < mdbreakpoint ? 48 : 54,
      textAlign: "center",
    },
    h2: {
      fontSize: width < smBreakpoint ? 28 : width < mdbreakpoint ? 34 : 40,
    },
    h3: {
      fontSize: width < smBreakpoint ? 22 : width < mdbreakpoint ? 24 : 28,
    },
    pushButton: {
      borderWidth: 1,
      borderColor: theme.foregroundColor,
      borderRadius: 5,
      // padding: 5,
      justifyContent: "center",
      height: 100,
      // width: 222,
      marginHorizontal: width < mdbreakpoint ? 0 : 5,
      marginVertical: 5,
    },
    timeSlot: { width: width < lgBreakpoint ? 140 : 222 },
    item: { width: 222 },
  });

  type Experience = "Wheel" | "HB" | "OW";
  type Time = "1" | "4" | "7" | "10" | "3" | "6" | "9";
  type Day = "1/17" | "1/18" | "1/19" | "1/24" | "1/25" | "1/26" | "1/31" | "2/1" | "2/2" | "2/7" | "2/8" | "2/9" | "2/14" | "2/15" | "2/16" | "2/21" | "2/22" | "2/23" | "2/28" | "3/1" | "3/2";
  type Slot = { time: Time; day: Day };
  type PaymentLink = {
    experience: Experience;
    // time: Time;
    // day: Day;
    slot: Slot;
    paymentLink: string;
    price: string;
    isAvailable?: boolean;
  };



  const [selectedExperience, setSelectedExperience] = useState<Experience>("Wheel");

  const soldOutTimeSlots: Slot[] = [
  ];

  const containsObject = (obj: Slot, list: Slot[]) => {
    return list.some((e) => JSON.stringify(e) === JSON.stringify(obj));
  };

  const data = [
    {
      product: "Wheel",
      name: "Wheel Throwing Class",
      description:
        "This low-key workout is a full body exercise in calming the mind and controlling the hands. Harness centrifugal force to squeeze, lift and shape a mound of  clay into the vessel of your dreams. If you're new to this, the objective is to make as many pots as you can in 2 hours. You'll get better with time. Good for: pots, cups, bowls, small vases, and ashtrays with uniform shape.",
      id: "wheeeeel",
      assets: [
        {
          id: "w1",
          url: "",
          height: 100,
          width: 100,
          caption: "",
        },
      ],
    },
    {
      product: "HB",
      name: "Hand Building Class",
      description:
        "A more controlled approach to pottery, hand building is like table-top sculpting. Use your hands, carving tools, and coiling techniques to shape the clay into anything you want. Good for: ashtrays, figurines, tiles, and cups with intricate details.",
      id: "hand",
      assets: [
        {
          id: "h1",
          url: "",
          height: 100,
          width: 100,
          caption: "",
        },
      ],
    },
    {
      product: "OW",
      name: "Open Wheel Session",
      description:
        "Designed for those who have taken our Wheel Class and want to get more practice time in. This is an uninstructed, two-hour time slot in The Pottery to get your reps in. This session only includes 5lbs of clay - firing services & more clay available in the studio for an additional fee. You must take our Wheel Experience class first, regardless of previous experience!",
      id: "open",
      assets: [
        {
          id: "h1",
          url: "",
          height: 100,
          width: 100,
          caption: "",
        },
      ],
    },
  ];

  const paymentLinks: PaymentLink[] = [
    {
      experience: "Wheel",
      slot: { day: "1/24", time: "4" },
      paymentLink: "https://buy.stripe.com/3cs5o0e9m8ii3It3gG",
      price: "$120",
      isAvailable: false,
    },
    {
      experience: "Wheel",
      slot: { day: "1/24", time: "7" },
      paymentLink: "https://buy.stripe.com/4gw17KaXa0PQ1AlcRi",
      price: "$120",
      isAvailable: false,
    },
    {
      experience: "Wheel",
      slot: { day: "1/25", time: "1" },
      paymentLink: "https://buy.stripe.com/8wM03G5CQaqq3It18C",
      price: "$120",
      isAvailable: false,
    },
    {
      experience: "Wheel",
      slot: { day: "1/25", time: "4" },
      paymentLink: "https://buy.stripe.com/eVa4jW6GU566frbbNi",
      price: "$120",
      isAvailable: false,
    },
    {
      experience: "Wheel",
      slot: { day: "1/25", time: "7" },
      paymentLink: "https://buy.stripe.com/aEUaIk1mA0PQbaV4kS",
      price: "$120",
      isAvailable: false,
    },
    {
      experience: "Wheel",
      slot: { day: "1/26", time: "10" },
      paymentLink: "https://buy.stripe.com/4gw6s4c1e6aa1Al4kU",
      price: "$120",
      isAvailable: false,
    },
    {
      experience: "Wheel",
      slot: { day: "1/26", time: "1" },
      paymentLink: "https://buy.stripe.com/bIYg2E6GUaqqbaV5p0",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "1/26", time: "4" },
      paymentLink: "https://buy.stripe.com/9AQ7w8aXa0PQ0whdVy",
      price: "$120",
      isAvailable: true,
    },

    {
      experience: "HB",
      slot: { day: "1/24", time: "4" },
      paymentLink: "https://buy.stripe.com/4gw7w81mA2XY7YJ2cD",
      price: "$68",
      isAvailable: false,
    },
    {
      experience: "HB",
      slot: { day: "1/24", time: "7" },
      paymentLink: "https://buy.stripe.com/8wM3fS9T6eGGgvfdVn",
      price: "$68",
      isAvailable: false,
    },
    {
      experience: "HB",
      slot: { day: "1/25", time: "1" },
      paymentLink: "https://buy.stripe.com/fZeg2E7KYcyy7YJ4kP",
      price: "$68",
      isAvailable: false,
    },
    {
      experience: "HB",
      slot: { day: "1/25", time: "4" },
      paymentLink: "https://buy.stripe.com/9AQdUw8P2fKKa6R04B",
      price: "$68",
      isAvailable: false,
    },
    {
      experience: "HB",
      slot: { day: "1/25", time: "7" },
      paymentLink: "https://buy.stripe.com/5kA2bO1mAdCCceZ7x5",
      price: "$68",
      isAvailable: false,
    },
    {
      experience: "HB",
      slot: { day: "1/26", time: "10" },
      paymentLink: "https://buy.stripe.com/3cs6s4c1e1TUgvfaJj",
      price: "$68",
      isAvailable: false,
    },
    {
      experience: "HB",
      slot: { day: "1/26", time: "1" },
      paymentLink: "https://buy.stripe.com/5kA5o06GU566frb7x9",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "1/26", time: "4" },
      paymentLink: "https://buy.stripe.com/5kA5o0d5ibuu6UF18N",
      price: "$68",
      isAvailable: true,
    },

    {
      experience: "OW",
      slot: { day: "1/25", time: "10" },
      paymentLink: "https://buy.stripe.com/14keYA3uI9mmceZ5pr",
      price: "$55",
      isAvailable: false,
    },
    {
      experience: "OW",
      slot: { day: "1/26", time: "7" },
      paymentLink: "https://buy.stripe.com/7sIaIkfdqeGG92N8BE",
      price: "$55",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "1/31", time: "4" },
      paymentLink: "https://buy.stripe.com/8wMcQsghudCCdj38Bg",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "1/31", time: "7" },
      paymentLink: "https://buy.stripe.com/6oEg2E4yM7ee7YJeZG",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/1", time: "1" },
      paymentLink: "https://buy.stripe.com/dR64jWghu7eebaV2cW",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/1", time: "4" },
      paymentLink: "https://buy.stripe.com/cN25o08P2gOOen7eZL",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/1", time: "7" },
      paymentLink: "https://buy.stripe.com/8wM17K3uIcyy0whdVJ",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/2", time: "10" },
      paymentLink: "https://buy.stripe.com/28o03G3uI0PQ2Ep04V",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/2", time: "1" },
      paymentLink: "https://buy.stripe.com/14kdUwe9mbuuen78Bt",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/2", time: "4" },
      paymentLink: "https://buy.stripe.com/4gw4jWaXaaqq92NbNH",
      price: "$120",
      isAvailable: true,
    },

    {
      experience: "HB",
      slot: { day: "1/31", time: "4" },
      paymentLink: "https://buy.stripe.com/bIY9Egc1egOOdj37xd",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "1/31", time: "7" },
      paymentLink: "https://buy.stripe.com/aEUbMo9T6fKK5QB8Bj",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/1", time: "1" },
      paymentLink: "https://buy.stripe.com/00gcQs2qE4221Al2cX",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/1", time: "4" },
      paymentLink: "https://buy.stripe.com/28o2bO5CQfKK6UF5pc",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/1", time: "7" },
      paymentLink: "https://buy.stripe.com/8wM4jW3uI1TUa6R9Fu",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/2", time: "10" },
      paymentLink: "https://buy.stripe.com/00gaIk4yMcyy92N5pg",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/2", time: "1" },
      paymentLink: "https://buy.stripe.com/28o03G6GUdCC0whg3W",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/2", time: "4" },
      paymentLink: "https://buy.stripe.com/5kA3fS1mA1TU92Ng3Y",
      price: "$68",
      isAvailable: true,
    },

    {
      experience: "OW",
      slot: { day: "2/1", time: "10" },
      paymentLink: "",
      price: "$55",
      isAvailable: true,
    },
    {
      experience: "OW",
      slot: { day: "2/2", time: "7" },
      paymentLink: "",
      price: "$55",
      isAvailable: true,
    },


    {
      experience: "Wheel",
      slot: { day: "2/7", time: "4" },
      paymentLink: "https://buy.stripe.com/aEU5o06GU2XY0wh3lE",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/7", time: "7" },
      paymentLink: "https://buy.stripe.com/eVaeYA8P2buu2Ep8FZ",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/8", time: "1" },
      paymentLink: "https://buy.stripe.com/dR69EgaXaaqqen7g8s",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/8", time: "4" },
      paymentLink: "https://buy.stripe.com/cN203G4yM0PQ3It8G1",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/8", time: "7" },
      paymentLink: "https://buy.stripe.com/fZe8Ace9mfKK1Al8G2",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/9", time: "10" },
      paymentLink: "https://buy.stripe.com/8wMg2EfdqdCC5QB8G3",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/9", time: "1" },
      paymentLink: "https://buy.stripe.com/28o3fS4yM0PQ1Al5tS",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/9", time: "4" },
      paymentLink: "https://buy.stripe.com/eVacQsfdqdCCceZcWl",
      price: "$120",
      isAvailable: true,
    },

    {
      experience: "HB",
      slot: { day: "2/7", time: "4" },
      paymentLink: "https://buy.stripe.com/aEU4jW9T68iibaV3m8",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/7", time: "7" },
      paymentLink: "https://buy.stripe.com/9AQbMoe9m8ii7YJg8V",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/8", time: "1" },
      paymentLink: "https://buy.stripe.com/7sI03G0iwdCC92NcWK",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/8", time: "4" },
      paymentLink: "https://buy.stripe.com/9AQ7w8d5i7ee1Al3mb",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/8", time: "7" },
      paymentLink: "https://buy.stripe.com/dR63fSc1efKK2Ep5uk",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/9", time: "10" },
      paymentLink: "https://buy.stripe.com/00gg2Ec1egOO2Epg8Z",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/9", time: "1" },
      paymentLink: "https://buy.stripe.com/aEUcQsghueGG4Mx7Cu",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/9", time: "4" },
      paymentLink: "https://buy.stripe.com/cN217K3uIdCC92NaOH",
      price: "$68",
      isAvailable: true,
    },

    {
      experience: "OW",
      slot: { day: "2/8", time: "10" },
      paymentLink: "https://buy.stripe.com/aEU9Egghu7eeen7aP4",
      price: "$55",
      isAvailable: true,
    },
    {
      experience: "OW",
      slot: { day: "2/9", time: "7" },
      paymentLink: "https://buy.stripe.com/5kA17Kd5i422baV9L1",
      price: "$55",
      isAvailable: true,
    },


    {
      experience: "Wheel",
      slot: { day: "2/14", time: "3" },
      paymentLink: "https://buy.stripe.com/28oaIk4yM0PQ4Mx8Fg",
      price: "$300",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/14", time: "6" },
      paymentLink: "https://buy.stripe.com/6oE7w88P2gOOdj38Fh",
      price: "$300",
      isAvailable: false,
    },
    {
      experience: "Wheel",
      slot: { day: "2/14", time: "9" },
      paymentLink: "https://buy.stripe.com/6oEdUwc1eaqq92N2gU",
      price: "$300",
      isAvailable: false,
    },
    {
      experience: "Wheel",
      slot: { day: "2/15", time: "1" },
      paymentLink: "https://buy.stripe.com/5kA9Egfdq9mm3It7C2",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/15", time: "4" },
      paymentLink: "https://buy.stripe.com/9AQ4jW3uI8ii1Alg8z",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/15", time: "7" },
      paymentLink: "https://buy.stripe.com/aEU8Acd5i0PQ6UF2hK",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/16", time: "10" },
      paymentLink: "https://buy.stripe.com/7sI4jW3uIaqq2EpcWp",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/16", time: "1" },
      paymentLink: "https://buy.stripe.com/fZe4jW9T65662Ep1dI",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/16", time: "4" },
      paymentLink: "https://buy.stripe.com/6oEg2E3uIdCCbaV6y3",
      price: "$120",
      isAvailable: true,
    },

    {
      experience: "HB",
      slot: { day: "2/14", time: "3" },
      paymentLink: "https://buy.stripe.com/9AQ7w8ghu0PQbaVbRv",
      price: "$200",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/14", time: "6" },
      paymentLink: "https://buy.stripe.com/7sI3fS3uI566gvf3l0",
      price: "$200",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/14", time: "9" },
      paymentLink: "https://buy.stripe.com/3cs17K5CQ566ceZg7N",
      price: "$200",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/15", time: "1" },
      paymentLink: "https://buy.stripe.com/14kg2EfdqdCC6UF5uo",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/15", time: "4" },
      paymentLink: "https://buy.stripe.com/6oEeYAghugOOdj3aOJ",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/15", time: "7" },
      paymentLink: "https://buy.stripe.com/cN29Eg5CQaqq4Mxg94",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/16", time: "10" },
      paymentLink: "https://buy.stripe.com/dR603G2qE5665QBbSP",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/16", time: "1" },
      paymentLink: "https://buy.stripe.com/bIY8Acd5ieGGfrbf52",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/16", time: "4" },
      paymentLink: "https://buy.stripe.com/14k7w8c1e8iien72ih",
      price: "$68",
      isAvailable: true,
    },

    {
      experience: "OW",
      slot: { day: "2/15", time: "10" },
      paymentLink: "https://buy.stripe.com/eVa6s48P27eefrb3mE",
      price: "$55",
      isAvailable: true,
    },
    {
      experience: "OW",
      slot: { day: "2/16", time: "7" },
      paymentLink: "https://buy.stripe.com/6oEaIkd5i0PQ0wh2iB",
      price: "$55",
      isAvailable: true,
    },


    {
      experience: "Wheel",
      slot: { day: "2/21", time: "4" },
      paymentLink: "https://buy.stripe.com/cN2eYAc1e0PQa6R5u0",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/21", time: "7" },
      paymentLink: "https://buy.stripe.com/eVa4jW6GUeGGa6R7C9",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/22", time: "1" },
      paymentLink: "https://buy.stripe.com/4gw03G9T6buu4Mx7Ca",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/22", time: "4" },
      paymentLink: "https://buy.stripe.com/cN2bMo2qE5665QB9Kj",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/22", time: "7" },
      paymentLink: "https://buy.stripe.com/4gwg2E2qE566a6Rg8I",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/23", time: "10" },
      paymentLink: "https://buy.stripe.com/eVabMod5i2XY92N8Gh",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/23", time: "1" },
      paymentLink: "https://buy.stripe.com/00geYA8P28ii2Ep6ya",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/23", time: "4" },
      paymentLink: "https://buy.stripe.com/6oE9Eg0iw7ee2Ep7Cf",
      price: "$120",
      isAvailable: true,
    },

    {
      experience: "HB",
      slot: { day: "2/21", time: "4" },
      paymentLink: "https://buy.stripe.com/4gw8Acd5i7ee6UF2ii",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/21", time: "7" },
      paymentLink: "https://buy.stripe.com/4gw3fS3uIdCC0whg99",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/22", time: "1" },
      paymentLink: "https://buy.stripe.com/5kA17Kfdqcyy4MxcWY",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/22", time: "4" },
      paymentLink: "https://buy.stripe.com/28o3fSd5i8ii1Al2il",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/22", time: "7" },
      paymentLink: "https://buy.stripe.com/6oE3fSe9meGGgvff58",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/23", time: "10" },
      paymentLink: "https://buy.stripe.com/4gw17K2qE7ee3It0af",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/23", time: "1" },
      paymentLink: "https://buy.stripe.com/dR617K2qE422frb5uA",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/23", time: "4" },
      paymentLink: "https://buy.stripe.com/00gcQs9T6cyyen7f5b",
      price: "$68",
      isAvailable: true,
    },

    {
      experience: "OW",
      slot: { day: "2/22", time: "10" },
      paymentLink: "https://buy.stripe.com/5kAeYAe9m2XY0wh1ey",
      price: "$55",
      isAvailable: true,
    },
    {
      experience: "OW",
      slot: { day: "2/23", time: "7" },
      paymentLink: "https://buy.stripe.com/7sIg2EaXa2XYceZg9t",
      price: "$55",
      isAvailable: true,
    },

    {
      experience: "Wheel",
      slot: { day: "2/28", time: "4" },
      paymentLink: "https://buy.stripe.com/28o9Eg7KY8ii6UFf4I",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "2/28", time: "7" },
      paymentLink: "https://buy.stripe.com/cN27w88P2cyy92N2hX",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "3/1", time: "1" },
      paymentLink: "https://buy.stripe.com/14kg2E5CQ6aafrb6ye",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "3/1", time: "4" },
      paymentLink: "https://buy.stripe.com/cN2aIk5CQ8ii7YJ9Kr",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "3/1", time: "7" },
      paymentLink: "https://buy.stripe.com/3csbMoe9m4221AlcWE",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "3/2", time: "10" },
      paymentLink: "https://buy.stripe.com/14kg2E0iw8ii92N5ud",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "3/2", time: "1" },
      paymentLink: "https://buy.stripe.com/6oE3fS2qE6aabaVf4O",
      price: "$120",
      isAvailable: true,
    },
    {
      experience: "Wheel",
      slot: { day: "3/2", time: "4" },
      paymentLink: "https://buy.stripe.com/14k5o00iwaqq92NcWH",
      price: "$120",
      isAvailable: true,
    },

    {
      experience: "HB",
      slot: { day: "2/28", time: "4" },
      paymentLink: "https://buy.stripe.com/4gw4jWfdq5666UFg9g",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "2/28", time: "7" },
      paymentLink: "https://buy.stripe.com/9AQdUw3uIfKK3It1en",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "3/1", time: "1" },
      paymentLink: "https://buy.stripe.com/dR62bO8P2fKK5QBe1a",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "3/1", time: "4" },
      paymentLink: "https://buy.stripe.com/3cscQs8P242292N5uF",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "3/1", time: "7" },
      paymentLink: "https://buy.stripe.com/9AQ3fS4yM2XY5QBe1c",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "3/2", time: "10" },
      paymentLink: "https://buy.stripe.com/4gwcQs8P2422gvf3mz",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "3/2", time: "1" },
      paymentLink: "https://buy.stripe.com/4gwcQs8P2422gvf3mz",
      price: "$68",
      isAvailable: true,
    },
    {
      experience: "HB",
      slot: { day: "3/2", time: "4" },
      paymentLink: "https://buy.stripe.com/28odUwe9m7eegvf8GV",
      price: "$68",
      isAvailable: true,
    },

    {
      experience: "OW",
      slot: { day: "3/1", time: "10" },
      paymentLink: "https://buy.stripe.com/00g7w83uIaqq6UFg9u",
      price: "$55",
      isAvailable: true,
    },
    {
      experience: "OW",
      slot: { day: "3/2", time: "7" },
      paymentLink: "https://buy.stripe.com/cN24jWe9m8iifrb1eB",
      price: "$55",
      isAvailable: true,
    },

  ];

  const firstAvailWheel = paymentLinks.filter(l => l.experience === 'Wheel' && l.isAvailable === true)[0].slot
  const [selectedTime, setSelectedTime] = useState<Time>(firstAvailWheel.time);
  const [selectedDay, setSelectedDay] = useState<Day>(firstAvailWheel.day);

  interface TimeSlotProps {
    day: Day;
    time: Time;
    slot: Slot;
    isAvailable?: boolean;
  }

  const TimeSlot = ({ day, time, slot, isAvailable = true }: TimeSlotProps) => (
    <TouchableOpacity
      disabled={!isAvailable || containsObject(slot, soldOutTimeSlots)}
      style={{ margin: 5, maxWidth: '45%' }}
      onPress={() => {
        setSelectedDay(day);
        setSelectedTime(time);
      }}
    >
      <View
        style={[
          styles.pushButton,
          styles.timeSlot,
          {
            opacity: isAvailable === false ? 0.5 : 1,
            backgroundColor:
              selectedDay === day && selectedTime === time
                ? theme.foregroundColor
                : theme.backgroundColor,
          },
        ]}
      >
        <P
          style={[
            styles.text,
            styles.body,
            {
              color:
                selectedDay === day && selectedTime === time
                  ? theme.backgroundColor
                  : theme.foregroundColor,
              textAlign: 'center',
            },
          ]}
        >
          {containsObject(slot, soldOutTimeSlots) || isAvailable === false
            ? 'Sold Out'
            : `${time}:00`}
          {/* {!isAvailable} */}
        </P>
      </View>
    </TouchableOpacity>
  );

  return (
    <>
      <ScrollView
        style={{
          flex: 1,
          backgroundColor: theme.backgroundColor,
        }}
      >
        <CuratorHeader />
        <View style={{ height: width < mdbreakpoint ? 20 : 40 }} />
        <View
          style={{
            justifyContent: 'center',
            width: 700,
            maxWidth: '90vw',
            alignSelf: 'center',
          }}
        >
          <H1 style={[styles.header, { marginBottom: 0 }]}>
            The Pottery at Happy Medium
          </H1>
          <P
            style={[
              styles.text,
              styles.body,
              {
                alignSelf: 'center',
                marginTop: 0,
                letterSpacing: 1.2,
                marginBottom: 0,
                width: 280,
                textAlign: 'center',
              },
            ]}
          >
            open every {'\n'} Friday, Saturday & Sunday{'\n'}
          </P>
          <Text
            style={[
              styles.text,
              styles.body,
              {
                alignSelf: 'center',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: 10,
                letterSpacing: 1,
                fontWeight: 'bold',
                fontSize: 16,
              },
            ]}
          >
            49 Market St{"\n"}Ground Floor{"\n"}NY, NY 10002
          </Text>
          {/* <P style={[styles.text, styles.body]}>
            Back soon with info and tickets! Leave your number below to be
            the first to know when info and tickets are released :)
          </P>
          <View style={{ alignSelf: 'center' }}>
            <PhoneNumberSignupForm />
          </View> */}
          <View style={{ height: 20 }} />

          <P style={[styles.text, styles.body]}>
            The Pottery is a beginner-friendly pottery studio on the first floor of Happy Medium's 49 Market St. location offering wheel throwing and hand-building classes to everyone 16 & up.{"\n\n"}Classes are 2 hours long and each ticket includes  admission for one person, a 20 minute demo + 1:1 instruction throughout, unlimited clay, one fully glaze-fired pot, and all cleanup. If you fall in love with more than one of your pieces, additional pieces are available to purchase for $25.
          </P>
          <P style={[styles.text, styles.body]}>
            We also offer additional services to fully customize your pot and get it home to you safely. Each add on is $25 and can be purchased after your class.
            <LI>
              <B>Custom design: </B>want to do something custom? Come back to the Art Cafe and paint your pot! Appointments are available once your piece is bisque fired (2-3 weeks after your class) and include unlimited glaze and 2 hours in the Art Cafe on the second floor.
            </LI>
            <LI>
              <B>Just make it a candle: </B>love your piece, but not quite sure what to do with it? Just make it a candle. Choose your scent after your class and we will hand-pour it for you before you pick it up!
            </LI>
            <LI>
              <B>Shipping: </B>Don't live in NYC? No problem. We offer shipping all around the world. US shipping is $25 and international starts at $40.
            </LI>
          </P>
          <P style={[styles.text, styles.body]}>
            Open Wheel sessions are our version of a “build-your-own” membership. They're available to anyone who has taken our wheel throwing classes and include 5lbs of clay and 2 hours on the wheel. Additional clay and firing services are available for purchase at the studio.
          </P>
          <P style={[styles.text, styles.body]}>
            Processing time for pottery is 3-4 weeks and available for pickup on Thursdays from 5:30pm-9:30pm, Fridays from 4-9pm, Saturdays from 10am-9pm and Sundays from 10am-6pm on the first floor of our studio.
          </P>
          <P style={[styles.text, styles.body, { fontSize: 16, fontWeight: 'bold' }]}>
            **Please note, our tickets are non-refundable. You must notify us at pottery@happy-medium.co at least 48 hours before your time slot in order to be eligible to reschedule. Otherwise, the full price will be forfeit. If you purchase your ticket within 48 hours of the start time it is not eligible to be rescheduled.**
          </P>
          <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Step 1</H2>
          <P
            style={[
              styles.text,
              styles.body,
              { alignSelf: 'center', marginTop: 0, letterSpacing: 1.2 },
            ]}
          >
            Choose your experience
          </P>
          <View
            style={{
              flexDirection: width < 800 ? 'column' : 'row',
              justifyContent: 'center',
              width: '100%',
              alignContent: 'center',
              alignSelf: 'center',
            }}
          >

            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedExperience('Wheel')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedExperience === 'Wheel'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedExperience === 'Wheel'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Wheel Throwing
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedExperience('HB')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedExperience === 'HB'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedExperience === 'HB'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Hand Building
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedExperience('OW')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedExperience === 'OW'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedExperience === 'OW'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Open Wheel
                </P>
              </View>
            </TouchableOpacity>

          </View>
        </View>
        <View style={{ height: 20 }} />

        {/* <ImageCarousel assets={data.filter(i => i.product === selectedDesign)?.flatMap(i => i.assets)} /> */}

        <View
          style={{
            justifyContent: 'center',
            width: 700,
            maxWidth: '90vw',
            alignSelf: 'center',
          }}
        >
          <H1 style={[styles.header, { alignSelf: 'flex-start', marginBottom: 0 }]}>
            {data.find((i) => i.product === selectedExperience)?.name}
          </H1>
          <P style={[styles.text, styles.body]}>{data.find((i) => i.product === selectedExperience)?.description}</P>
          {/* <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Step 2</H2>
        <P
          style={[
            styles.text,
            styles.body,
            { alignSelf: 'center', marginTop: 0, letterSpacing: 1.2 },
          ]}
        >
          Choose your wood
        </P>
          <View style={{flexDirection: 'row', width: '100%', justifyContent: 'center'}}>
        <TouchableOpacity style={{margin: 5}} onPress={() => setSelectedWood('Douglas Fir')}>
            <View
              style={{
                borderWidth: 1,
                borderColor: theme.foregroundColor,
                backgroundColor:
                  selectedWood === 'Douglas Fir'
                    ? theme.foregroundColor
                    : theme.backgroundColor,
                borderRadius: 5,
                padding: 10,
                width: 140
              }}
            >
              <P
                style={[
                  styles.text,
                  styles.body,
                  {
                    color:
                      selectedWood === 'Douglas Fir'
                        ? theme.backgroundColor
                        : theme.foregroundColor,
                    textAlign: 'center'
                  },
                ]}
              >
                Douglas Fir{'\n'}
                <Text style={[styles.text, styles.body, {fontSize: 12, color:
                      selectedWood === 'Douglas Fir'
                        ? theme.backgroundColor
                        : theme.foregroundColor,}]}>(light)</Text>
              </P>
            </View>
          </TouchableOpacity>
        <TouchableOpacity style={{margin: 5}} onPress={() => setSelectedWood('Mahogany')}>
            <View
              style={{
                borderWidth: 1,
                borderColor: theme.foregroundColor,
                backgroundColor:
                  selectedWood === 'Mahogany'
                    ? theme.foregroundColor
                    : theme.backgroundColor,
                borderRadius: 5,
                padding: 10,
                width: 140
              }}
            >
              <P
                style={[
                  styles.text,
                  styles.body,
                  {
                    color:
                      selectedWood === 'Mahogany'
                        ? theme.backgroundColor
                        : theme.foregroundColor,
                    textAlign: 'center'
                  },
                ]}
              >
                Mahogany{'\n'}
                <Text style={[styles.text, styles.body, {fontSize: 12, color:
                      selectedWood === 'Mahogany'
                        ? theme.backgroundColor
                        : theme.foregroundColor,}]}>(dark)</Text>
              </P>
            </View>
          </TouchableOpacity> */}
        </View>
        <View style={{ height: 60 }} />
        <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Step 2</H2>
        <P
          style={[
            styles.text,
            styles.body,
            { textAlign: 'center', alignSelf: 'center', marginTop: 0, letterSpacing: 1.2 },
          ]}
        >
          Choose your day and time{'\n'}
          <Text style={[styles.text, styles.body, { fontSize: 12 }]}>(each experience lasts up to 2 hours)</Text>
        </P>


        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center', flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Friday 1/24</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '1/24').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Saturday 1/25</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '1/25').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Sunday 1/26</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '1/26').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
        </View>

        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center', flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Friday 1/31</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '1/31').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Saturday 2/1</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '2/1').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Sunday 2/2</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '2/2').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
        </View>

        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center', flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Friday 2/7</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '2/7').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Saturday 2/8</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '2/8').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Sunday 2/9</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '2/9').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
        </View>

        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center', flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Friday 2/14</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '2/14').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Saturday 2/15</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '2/15').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Sunday 2/16</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '2/16').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
        </View>

        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center', flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Friday 2/21</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '2/21').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Saturday 2/22</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '2/22').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Sunday 2/23</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '2/23').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
        </View>

        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center', flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Friday 2/28</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '2/28').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Saturday 3/1</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '3/1').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
          <View style={{ flexDirection: 'column', maxWidth: '45%' }}>
            <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Sunday 3/2</H3>
            {paymentLinks.filter(l => l.experience === selectedExperience && l.slot.day === '3/2').map(l => {
              return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.experience}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} />
            })}

          </View>
        </View>


        {/* </View> */}
        <View style={{ height: 40 }} />


        <View style={{ height: 60 }} />
        {/* </View> */}
      </ScrollView>

      <TouchableOpacity disabled={false} style={{}} onPress={() => window.open(paymentLinks.find(l => l.experience === selectedExperience && l.slot.day === selectedDay && l.slot.time === selectedTime)?.paymentLink, '__blank')}>
        <View style={{ position: 'absolute', bottom: 0, right: width < mdbreakpoint ? undefined : 0, backgroundColor: theme.foregroundColor, borderRadius: 50, flexDirection: 'row', justifyContent: 'space-between', padding: 15, paddingHorizontal: 60, margin: 20, width: 320, alignSelf: 'center', borderWidth: 1, borderColor: theme.backgroundColor }}>
          {/* <Text style={[styles.text, styles.h3, {color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1}]}>Reserve Now</Text> */}

          <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>Checkout</Text>
          <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>{paymentLinks.find(l => l.experience === selectedExperience && l.slot.day === selectedDay && l.slot.time === selectedTime)?.price}</Text>
        </View>
      </TouchableOpacity>
    </>

  );
};
